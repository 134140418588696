<template>
  <div class="mix-report">
    <div class="mainBox">
      <el-form v-model="filter" size="mini">
        <el-row class="border-bottom-1 mb10">
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <el-form-item label="Date" label-width="40px">
              <el-date-picker
                v-model="startToEndDate"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                align="right"
                class="w100"
                unlink-panels
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <el-form-item label="OfferIds" label-width="80px">
              <el-input v-model="filter.offerIds" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <el-form-item label="AffiliateIds" label-width="90px">
              <group-select
                v-model="filter.affiliateIds"
                :data-source="affiliatesGroupList"
                multiple
                reserve-keyword
                :loading="affiliateLoading"
                clearable
                filterable
                collapse-tags
                size="small"
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" align="right">
            <el-form-item class="mb0">
              <el-button type="primary" @click="getDataList(1)">Search</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        :data="dataList"
        :header-cell-style="() => 'text-align:center'"
        :cell-style="() => 'text-align:center'"
        v-loading="tableLoading"
        size="mini"
        stripe
        border
      >
        <el-table-column prop="offerId" label="Offer ID" width="100"></el-table-column>
        <el-table-column prop="sendAffiliate" label="Send Aff" min-width="100"></el-table-column>
        <el-table-column prop="sendAffsub" label="Send Aff Sub" min-width="120"></el-table-column>
        <el-table-column prop="mixAffiliate" label="Mix Aff" min-width="100"></el-table-column>
        <el-table-column prop="mixAffsub" label="Mix Aff Sub" min-width="100"></el-table-column>
        <el-table-column prop="clickCap" label="Click Cap" min-width="100"></el-table-column>
        <el-table-column prop="clickCount" label="Click Count" min-width="100"></el-table-column>
        <el-table-column
          prop="conversionCap"
          label="Conversion Cap"
          min-width="150"
        ></el-table-column>
        <el-table-column prop="conversionCount" label="Conversion Count" min-width="150">
        </el-table-column>
        <el-table-column prop="reportDate" label="Date" width="100"> </el-table-column>
      </el-table>
      <pagination
        class="block pagePanel"
        :pageTotal="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :pageSize="pageParam.pageSize"
        :currentPage="pageParam.page"
      ></pagination>
    </div>
  </div>
</template>

<script>
  import MixReportCon from '../../controllers/report/components/MixReport';

  export default {
    name: 'MixReport',
    ...MixReportCon,
  };
</script>

<style></style>

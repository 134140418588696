<template>
  <div class="mix-config">
    <div class="mainBox">
      <el-form v-model="filter" size="mini" class="list-filter">
        <el-row class="border-bottom-1 mb10">
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <el-form-item label="OfferIds" label-width="60px">
              <el-input v-model="filter.offerIds" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <el-form-item label="AffiliateIds" label-width="90px">
              <group-select
                v-model="filter.affiliateIds"
                :data-source="affiliatesGroupList"
                multiple
                reserve-keyword
                :loading="affiliateLoading"
                clearable
                filterable
                collapse-tags
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
            <el-form-item>
              <el-button type="primary" @click="getDataList(1)">Search</el-button>
              <el-button type="primary" @click="addMixConfigClick">Add</el-button>
              <el-button type="primary" @click="batchDelMixConfig">Delete</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        :data="dataList"
        :header-cell-style="() => 'text-align:center'"
        :cell-style="() => 'text-align:center'"
        @selection-change="handleSelectionChange"
        v-loading="tableLoading"
        size="mini"
        stripe
        border
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="offerId" label="Offer ID" width="100"></el-table-column>
        <el-table-column prop="type" label="Type" min-width="100">
          <template slot-scope="scope">
            <template v-for="item in typeOption">
              <span :key="item.value" v-if="item.value === scope.row.type">{{ item.label }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="sendAffiliate" label="Send Aff" min-width="100"></el-table-column>
        <el-table-column prop="sendAffsub" label="Send Aff Sub" min-width="120"></el-table-column>
        <el-table-column prop="mixAffiliate" label="Mix Aff" min-width="100"></el-table-column>
        <el-table-column prop="mixAffsub" label="Mix Aff Sub" min-width="100"></el-table-column>
        <el-table-column prop="mixCount" label="Mix Count" min-width="100"></el-table-column>
        <el-table-column prop="weight" label="Weight" min-width="100"></el-table-column>
        <el-table-column prop="createBy" label="Creator" width="100"></el-table-column>
        <el-table-column prop="createTime" label="CreateTime" width="170"></el-table-column>
      </el-table>
      <pagination
        class="block pagePanel"
        :pageTotal="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :pageSize="pageParam.pageSize"
        :currentPage="pageParam.page"
      ></pagination>
    </div>
    <el-dialog title="Add Mix Config" :visible.sync="dialogVisible" width="40%">
      <el-form :model="mixConfig" label-width="110px">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Offer ID">
              <el-input v-model="mixConfig.offerId" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Type">
              <el-select v-model="mixConfig.type" class="w100">
                <el-option
                  v-for="item in typeOption"
                  :label="item.label"
                  :key="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Send Aff">
              <el-input v-model="mixConfig.sendAffiliate" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Send Aff Sub">
              <el-input v-model="mixConfig.sendAffsub" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Mix Aff">
              <el-input v-model="mixConfig.mixAffiliate" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Mix Aff Sub">
              <el-input v-model="mixConfig.mixAffsub" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Mix Count">
              <el-input v-model="mixConfig.mixCount" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Weight">
              <el-input v-model="mixConfig.weight" />
            </el-form-item>
          </el-col>
          <el-col :span="24" align="right">
            <el-form-item label-width="0px" class="mb0">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addMixConfig">确 定</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import MixConfigCon from '../../controllers/report/components/MixConfig';

  export default {
    name: 'MixConfig',
    ...MixConfigCon,
  };
</script>

<style></style>

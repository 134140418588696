import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { getMixConfigList, addMixConfig, deleteConfig } from 'api/report';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  props: {
    affiliatesGroupList: {
      type: Array,
      default: () => [],
    },
    affiliateLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {},
      dataList: [],
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      tableLoading: false,
      startToEndDate: [],
      typeOption: [
        {
          label: 'click',
          value: 0,
        },
        {
          label: 'conversion',
          value: 1,
        },
      ],
      multipleSelection: [],
      dialogVisible: false,
      mixConfig: {},
    };
  },
  methods: {
    getDataList(curPage) {
      if (curPage) {
        this.currentPage = curPage;
      }
      const param = {
        ...this.pageParam,
      };
      for (const key in this.filter) {
        if (this.filter[key]) {
          if (Array.isArray(this.filter[key])) {
            this.filter[key].length > 0 && (param[key] = this.filter[key].toString());
          } else {
            param[key] = this.filter[key];
          }
        }
      }
      this.tableLoading = true;
      getMixConfigList(param)
        .then((response) => {
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addMixConfigClick() {
      this.dialogVisible = true;
      this.mixConfig = {};
    },
    addMixConfig() {
      addMixConfig({ ...this.mixConfig }).then((response) => {
        if (response.code === 200) {
          this.$message.success('Add Success');
          this.getDataList();
          this.dialogVisible = false;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    batchDelMixConfig() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择要删除的数据');
        return;
      }
      const ids = this.multipleSelection.map((item) => item.id).toString();
      deleteConfig(ids).then((response) => {
        if (response.code === 200) {
          this.$message.success('Delete Success');
          this.getDataList();
        } else {
          this.$message.error(response.message);
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.commonReportApi();
    },
  },
};

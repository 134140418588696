import { myMixin } from '@/mixins/mixins.js';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';

import { getMixReportList } from 'api/report';
import dateUtils from '@/utils/dateutils.js';

export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  props: {
    affiliatesGroupList: {
      type: Array,
      default: () => [],
    },
    affiliateLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {},
      dataList: [],
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      tableLoading: false,
      startToEndDate: [],
    };
  },
  mounted() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);
  },
  methods: {
    getDataList(curPage) {
      if (curPage) {
        this.currentPage = curPage;
      }
      const param = {
        ...this.pageParam,
      };
      for (const key in this.filter) {
        if (this.filter[key]) {
          if (Array.isArray(this.filter[key])) {
            this.filter[key].length > 0 && (param[key] = this.filter[key].toString());
          } else {
            param[key] = this.filter[key];
          }
        }
      }
      if (this.startToEndDate.length === 2) {
        const startDate = this.startToEndDate[0];
        const endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }
      this.tableLoading = true;
      getMixReportList(param)
        .then((response) => {
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.commonReportApi();
    },
  },
};
